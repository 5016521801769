import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchPages } from "../../slices/pageSlice";
import { Typography  } from "@mui/material";

function PageSettings() {
    const { pages , error } = useSelector((state) => state.pages);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(fetchPages());
    }, []);
    console.log("Sections Data ",pages)

    return <>
    <div>
        {pages == null ? <>
        <Typography>Loading .... </Typography></>:<>
            {pages.map(e=><>
                <h4>Page Name : {e.page_name}</h4>
                <Typography>{JSON.stringify(e.page_settings)}</Typography>
            </>)}
        </>}
    </div>
    </>
}

export default PageSettings