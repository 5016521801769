import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { fetchProduct } from "../../slices/productSlice";
import colors from "../../config/colours.json";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Title, ViewImage } from "../Post/Common/Ui";

function ViewProduct() {
    const { slug } = useParams();
    const { currentProduct: product, error } = useSelector((state) => state.products);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchProduct(slug));
    }, [slug]);


    return (
        <div>
            {product === null ? (
                <Typography>Loading...</Typography>
            ) : error ? (
                <Typography color="error">Error: {error}</Typography>
            ) : (
                <>
                <Paper
                    elevation={3}
                    style={{
                        padding: "20px",
                        marginTop: "20px",
                        width: "90%",
                        backgroundColor: colors.Primary,
                    }}
                >
                    {console.log(product, "product")}
                    <Title variant="h4">{product.title}</Title>
                    <Typography variant="subtitle1">
                        Author: {product.author_id}
                    </Typography>
                    <Typography variant="subtitle1">
                        Price: {product.price}
                    </Typography>
                    <Typography variant="subtitle1">
                        Status: {product.status}
                    </Typography>
                    <Typography variant="subtitle2">
                        Posted: {console.log(product.published_at, "published_at")}
                        {format(new Date(product.published_at), "dd/MM/yyyy")}
                    </Typography>
                    <Typography variant="subtitle1">
                        Cover Image
                    </Typography>
                    <ViewImage src={JSON.parse(product.content)[0]} alt={product.title} />
                    
                </Paper>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                        console.log("Edit");
                        navigate(`/product/${product.slug}/edit`);
                    }}
                >
                    Edit
                </Button>
                </>
            )}
        </div>
    );
}

export default ViewProduct;
