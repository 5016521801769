import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import colors from "../../config/colours.json";
import useAuth from "../../hooks/useAuth";
const AuthStatusContainer = styled(Box)`
    background-color: ${(props) => props.backgroundColor || colors.Secondary};
    color: ${colors.Text};
    text-align: center;
    padding: 10px 0;
`;

const AuthStatus = () => {
    const auth = useAuth();
    if (!auth.user) {
        return (
            <AuthStatusContainer backgroundColor={colors.Danger}>
                <Typography color="white" fontWeight={600} variant="body1">
                    You are not logged in.
                </Typography>
            </AuthStatusContainer>
        );
    }

    return (
        <AuthStatusContainer backgroundColor={colors.Accent}>
            <Typography variant="body1">
                Welcome {auth.user ? auth.user.username : ""}!{" "}
            </Typography>
        </AuthStatusContainer>
    );
};

export default AuthStatus;
