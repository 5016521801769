import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../config/api";

export const checkAuthStatus = () => async (dispatch) => {
    try {
        const response = await api.get("/user/status");
        if (response.data.isAuthenticated) {
            return dispatch(login.fulfilled(response.data.user));
        } else {
            return dispatch(logout());
        }
    } catch (error) {
        console.error("Failed to check auth status", error);
    }
};


export const signUp = createAsyncThunk(
    "auth/signUp",
    async ({ username, password, adminKey, orgName }, thunkAPI) => {
        try {
            const response = await api.post(
                "/user/signup",
                { username, password, orgName },
                {
                    headers: { "admin-api-key": adminKey },
                }
            );
            console.log(response.data, "response.data")
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const login = createAsyncThunk(
    "auth/login",
    async ({ username, password }, thunkAPI) => {
        try {
            const response = await api.post("/user/login", {
                username,
                password,
            });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
    try {
        await api.post("/user/logout");
        return null;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});

const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(signUp.fulfilled, (state, action) => {
                state.user = action.payload.user;
                state.error = null;
            })
            .addCase(signUp.rejected, (state, action) => {
                state.error = action.payload.error;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.user = action.payload;
                state.error = null;
            })
            .addCase(login.rejected, (state, action) => {
                state.error = action.payload.error;
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null;
            });
    },
});

export default authSlice.reducer;
