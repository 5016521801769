import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TextField, FormControlLabel, Radio } from "@mui/material";
import { useDropzone } from "react-dropzone";
import UploadWithPreview from "./Common/UploadWithPreview";
import {
    StyledLabel,
    Form,
    Title,
    SubmitButton,
    ErrorMessage,
    StyledRadioGroup,
    StyledTextArea,
} from "./Common/Ui";
import { fetchPost, updatePost } from "../../slices/postsSlice";
import { calculateFormDataSize } from "../../config/utils";
import { useNavigate } from "react-router-dom";
import PreviewPost from "./Preview";
import { SlugField } from "./Common/FormComponents";

// TODO: Make create and edit post forms common
function EditPost() {
    const { slug:slugUrl } = useParams();
    const { currentPost: post, error } = useSelector((state) => state.posts);
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!slugUrl || typeof slugUrl !== "string") return;
        dispatch(fetchPost(slugUrl));
    }, [slugUrl]);

    const [mainImage, setMainImage] = useState(null);
    const [mainDescription, setMainDescription] = useState("");
    const [image1, setImage1] = useState("");
    const [image2, setImage2] = useState("");
    const [description2, setDescription2] = useState("");
    const [additionalMedia, setAdditionalMedia] = useState("");
    const [title, setTitle] = useState("");
    const [slug, setSlug] = useState("");
    const [content, setContent] = useState("");
    const [excerpt, setExcerpt] = useState("");
    const [status, setStatus] = useState("draft");
    const [metaTitle, setMetaTitle] = useState("");
    const [metaDescription, setMetaDescription] = useState("");
    const [metaKeywords, setMetaKeywords] = useState("");
    const [publishedAt, setPublishedAt] = useState(new Date());
    const [clickSubmit, setClickSubmit] = useState(false);
    const [id, setId] = useState(null);
    useEffect(() => {
        if (post) {
            console.log(post, "post data", post.id);
            setMainDescription(post.content.mainDescription);
            if (post.content["0"]) {
                setMainImage(post.content["0"]);
            }
            if (post.content["1"]) {
                setImage1(post.content["1"]);
            }
            if (post.content["2"]) {
                setImage2(post.content["2"]);
            }
            setDescription2(post.content.description2);
            if (post.content["3"]) {
                setAdditionalMedia(post.content["3"]);
            }
            setTitle(post.title);
            setSlug(post.slug);
            setContent(post.content);
            setExcerpt(post.excerpt);
            setStatus(post.status);
            setMetaTitle(post.metaTitle);
            setMetaDescription(post.metaDescription);
            setMetaKeywords(post.metaKeywords);
            setPublishedAt(post.published_at);
            setId(post.id);
        }
    }, [post]);

    const handleSubmit = (event) => {
        event.preventDefault();
        // ask user to confirm if they want to proceed
        if (
            !window.confirm(
                "Are you sure you want to update this post? This action cannot be undone."
            )
        )
            return;

        setContent(
            JSON.stringify({
                mainDescription,
                description2,
            })
        );
        setClickSubmit(true);
    };

    useEffect(() => {
        console.log(typeof content !== "string" || content === "" || !clickSubmit, "checking if we should not submit")
        console.log(id)
        if (typeof content !== "string" || content === "" || !clickSubmit)
            return;
        console.log("content post checking", content, typeof content);
        const formData = new FormData();
        formData.append("title", title);
        formData.append("slug", slug);
        formData.append("content", content);
        formData.append("excerpt", excerpt);
        formData.append("author_id", user.id);
        formData.append("status", status);
        formData.append("meta_title", metaTitle);
        formData.append("meta_description", metaDescription);
        formData.append("meta_keywords", metaKeywords);
        formData.append("published_at", publishedAt);
        // Create attachments array - order is important
        const attachments = [mainImage, image1, image2, additionalMedia];
        console.log("attachments", attachments)
        attachments.forEach((attachment) => {
            formData.append(`attachments[]`, attachment);
            // If attachment is a file, append 'file', else append an empty string
            if(attachment instanceof File) {
                console.log("file attachment", attachment, "adding to attachmentsTypes[]")
                formData.append(`attachmentsTypes[]`, 'file');
            } else {
                console.log("WHERE THE FUCK")
                formData.append(`attachmentsTypes[]`, '');
            }
        });
        const updatePostAsync = async () => {
            try {
                console.log("slug URL", formData, calculateFormDataSize(formData), slugUrl);
                const result = await dispatch(updatePost({id: id, formData: formData}));
                console.log("Post created successfully.", result);
                if(result.payload.affectedRows === 1) {
                    navigate(`/posts/`);
                }
            } catch (error) {
                console.error("Failed to create post: ", error);
            }
        };
        // Call the async function
        updatePostAsync();
        console.log("Post data:", formData, calculateFormDataSize(formData));
    }, [clickSubmit, content]);

    const onDropMainImage = useCallback((acceptedFiles) => {
        setMainImage(acceptedFiles[0]);
    }, []);

    const {
        getRootProps: getRootPropsMainImage,
        getInputProps: getInputPropsMainImage,
    } = useDropzone({
        onDrop: onDropMainImage,
        multiple: false,
        disabled: !!mainImage,
    });

    const onDropImage1 = useCallback((acceptedFiles) => {
        setImage1(acceptedFiles[0]);
    }, []);

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } =
        useDropzone({
            onDrop: onDropImage1,
            multiple: false,
            disabled: !!image1,
        });

    const onDropImage2 = useCallback((acceptedFiles) => {
        setImage2(acceptedFiles[0]);
    }, []);

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
        useDropzone({
            onDrop: onDropImage2,
            multiple: false,
            disabled: !!image2,
        });

    const onDropAdditionalMedia = useCallback((acceptedFiles) => {
        setAdditionalMedia(acceptedFiles[0]);
    }, []);

    const {
        getRootProps: getRootPropsAdditionalMedia,
        getInputProps: getInputPropsAdditionalMedia,
    } = useDropzone({
        onDrop: onDropAdditionalMedia,
        multiple: false,
        disabled: !!additionalMedia,
    });

    // render form with the same layout and elements as in `NewPost`
    return (
        <div
            style={{
                // two column grid
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                // center the grid
                placeContent: "center",
            }}
        >
            <Form onSubmit={handleSubmit}>
                <Title variant="h4">Edit Post</Title>
                <TextField
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />
                <SlugField 
                    slug={slug}
                    setSlug={setSlug}
                />
                <UploadWithPreview
                    label="Main Image"
                    value={mainImage}
                    setValue={setMainImage}
                />
                <StyledTextArea
                    minRows={3}
                    style={{ width: "100%" }}
                    aria-label="Main Description"
                    placeholder="Main Description"
                    value={mainDescription}
                    onChange={(e) => setMainDescription(e.target.value)}
                    required
                />
                <UploadWithPreview
                    label="Image 1"
                    value={image1}
                    setValue={setImage1}
                />
                <UploadWithPreview
                    label="Image 2"
                    value={image2}
                    setValue={setImage2}
                />
                <StyledTextArea
                    minRows={3}
                    style={{ width: "100%" }}
                    aria-label="Description 2"
                    placeholder="Description 2"
                    value={description2}
                    onChange={(e) => setDescription2(e.target.value)}
                />
                <UploadWithPreview
                    label="Additional Media"
                    value={additionalMedia}
                    setValue={setAdditionalMedia}
                />
                <TextField
                    label="Excerpt"
                    value={excerpt}
                    onChange={(e) => setExcerpt(e.target.value)}
                />
                <StyledLabel variant="body1">Status</StyledLabel>
                <StyledRadioGroup
                    row
                    aria-label="status"
                    name="row-radio-buttons-group"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                >
                    <FormControlLabel
                        value="draft"
                        control={<Radio />}
                        label="Draft"
                    />
                    <FormControlLabel
                        value="published"
                        control={<Radio />}
                        label="Published"
                    />
                    <FormControlLabel
                        value="archived"
                        control={<Radio />}
                        label="Archived"
                    />
                </StyledRadioGroup>
                <TextField
                    label="Meta Title"
                    value={metaTitle}
                    onChange={(e) => setMetaTitle(e.target.value)}
                />
                <TextField
                    label="Meta Description"
                    value={metaDescription}
                    onChange={(e) => setMetaDescription(e.target.value)}
                />
                <TextField
                    label="Meta Keywords"
                    value={metaKeywords}
                    onChange={(e) => setMetaKeywords(e.target.value)}
                />

                <SubmitButton type="submit" variant="contained">
                    Update Post
                </SubmitButton>
                {error && (
                    <ErrorMessage variant="body1">
                        {JSON.stringify(error)}
                    </ErrorMessage>
                )}
            </Form>
            <div>
                <Title
                    variant="h4"
                    // adding 40px padding top
                    style={{ paddingTop: "40px" }}
                >
                    Preview
                </Title>
                <PreviewPost
                    mainImage={mainImage}
                    mainDescription={mainDescription}
                    image1={image1}
                    image2={image2}
                    description2={description2}
                    additionalMedia={additionalMedia}
                    title={title}
                    slug={slug}
                    content={content}
                    excerpt={excerpt}
                    authorId={user.id}
                    status={status}
                    metaTitle={metaTitle}
                    metaDescription={metaDescription}
                    metaKeywords={metaKeywords}
                    publishedAt={publishedAt}
                />
            </div>
        </div>
    );
}

export default EditPost;
