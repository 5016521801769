import React, {useEffect} from "react";
import { Typography, Box, Grid, Paper } from "@mui/material";
import styled from "styled-components";
import colors from "../../config/colours.json";
import { checkType } from "../../config/utils";
import { format } from "date-fns";
const PreviewImage = styled.img`
    max-width: 100%;
    height: auto;
`;

const Title = styled(Typography)`
    color: ${colors.Text};
`;

function PreviewPost(postData) {
    useEffect(() => {
        // console.log("hello",postData)
    }, [])
    
    return (
        <Paper
            elevation={3}
            style={{
                padding: "20px",
                marginTop: "20px",
                width: "90%",
                backgroundColor: colors.Primary,
            }}
        >
            <Title variant="h4">{postData?.title || "Title Placeholder"}</Title>
            <Typography variant="subtitle1">
                Author: {postData?.authorId || "Author Placeholder"}
            </Typography>
            <Typography variant="subtitle2">
                Posted:{" "}
                {/* TODO: Fix issue with published at field */}
                {/* { */}
                {/* format(new Date(postData.published_at), "dd/MM/yyyy")*/}
            </Typography>
            {postData?.mainImage ? (
                <PreviewImage
                    src={
                        checkType(postData?.mainImage) === "URL"
                            ? postData?.mainImage
                            : URL.createObjectURL(postData?.mainImage)
                    }
                    alt=""
                />
            ) : (
                <Box>Main Image placeholder</Box>
            )}
            <Typography variant="body1" style={{ margin: "20px 0" }}>
                {postData?.mainDescription || "Main description placeholder"}
            </Typography>

            <Grid container spacing={2} style={{ margin: "20px 0" }}>
                <Grid item xs={6}>
                    {postData?.image1 ? (
                        <PreviewImage
                            src={
                                checkType(postData?.image1) === "URL"
                                    ? postData?.image1
                                    : URL.createObjectURL(postData?.image1)
                            }
                            alt=""
                        />
                    ) : (
                        <div>Image 1 placeholder</div>
                    )}
                </Grid>
                <Grid item xs={6}>
                    {postData?.image2 ? (
                        <PreviewImage
                            src={
                                checkType(postData?.image2) === "URL"
                                    ? postData?.image2
                                    : URL.createObjectURL(postData?.image2)
                            }
                            alt=""
                        />
                    ) : (
                        <div>Image 2 placeholder</div>
                    )}
                </Grid>
            </Grid>

            <Typography variant="body1" style={{ margin: "20px 0" }}>
                {postData?.description2 || "Second description placeholder"}
            </Typography>

            {postData?.additionalMedia ? (
                <PreviewImage
                    src={
                        checkType(postData?.additionalMedia) === "URL"
                            ? postData?.additionalMedia
                            : URL.createObjectURL(postData.additionalMedia)
                    }
                    alt=""
                    style={{ width: "100%", margin: "20px 0" }}
                />
            ) : (
                <div>Additional media placeholder</div>
            )}
        </Paper>
    );
}

export default PreviewPost;
