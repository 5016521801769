import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../config/api";

// Fetch all posts
export const fetchPages = createAsyncThunk("page/fetchAll", async (_, thunkAPI) => {
    try {
        const response = await api.get("/page/");
        console.log("response.data", response.data)
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});


// Update an existing product
export const updatePage = createAsyncThunk("page/updatePage", async ({id, formData:updatedPost}, thunkAPI) => {
    try {

        for (let pair of updatedPost.entries()) {
            console.log(pair[0]+ ', '+ pair[1]);
        }
        const response = await api.put(`/page/edit/${id}`, updatedPost, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});


const pageSlice = createSlice({
    name: "pages",
    initialState: {
        pages: [],
        error: null,
        currentProduct: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPages.fulfilled, (state, action) => {
                state.pages = action.payload;
                state.error = null;
            })
            .addCase(fetchPages.rejected, (state, action) => {
                state.error = action.payload.error;
            })
            .addCase(updatePage.fulfilled, (state, action) => {
                state.error = null;
            })
            .addCase(updatePage.rejected, (state, action) => {
                state.error = action.payload.error;
            })
    },
});

export default pageSlice.reducer;
