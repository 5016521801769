import React, { createContext, useContext, useEffect } from "react";
import {
    Link,
    Routes,
    Route,
    Navigate,
    useLocation,
    useNavigate,
    Outlet
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SignUp from "./Components/User/SignUp";
import Login from "./Components/User/Login";
import { login, logout } from "./slices/authSlice";
import Navbar from "./Components/Header";
import useAuth from "./hooks/useAuth";
import AuthContext from "./context/auth";
import CreatePost from "./Components/Post/CreatePost";
import CreateProduct from "./Components/Product/CreateProduct";
import ViewPosts from "./Components/Post/ViewPosts";
import ViewPost from "./Components/Post/ViewPost";
import "./App.css";
import EditPost from "./Components/Post/EditPost";
import ViewProducts from "./Components/Product/ViewProducts";
import ViewProduct from "./Components/Product/ViewProduct";
import EditPages from "./Components/Page/EditPages";

const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const { user, error } = useSelector((state) => state.auth);

    const signin = async (username, password, callback) => {
        const resultAction = await dispatch(login({ username, password }));
        if (login.fulfilled.match(resultAction)) {
            callback();
        } else {
            if (resultAction.payload) {
                // handle the error from the server
                console.log(resultAction.payload);
            } else {
                // handle the error not from the server
                console.log(resultAction);
            }
        }
    };

    const signout = async (callback) => {
        const resultAction = await dispatch(logout());
        if (logout.fulfilled.match(resultAction)) {
            callback();
        }
    };

    return (
        <AuthContext.Provider value={{ user, error, signin, signout }}>
            {children}
        </AuthContext.Provider>
    );
};

const RequireAuth = ({ children }) => {
    const auth = useAuth();
    const location = useLocation();

    if (!auth.user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

const Home = () => {
    return (
        <div>
            <h1>Home</h1>
        </div>
    );
};

function App() {
    const { user, error } = useSelector((state) => state.auth);
    useEffect(
        () => {
            console.log("user", user);
            console.log("error", error);
        },
        [user, error]
    )
    return (
        <AuthProvider>
            <Routes>
                <Route element={<Navbar />}>
                    <Route path="signup" element={<SignUp />} />
                    <Route path="login" element={<Login />} />
                    <Route
                        path="/"
                        element={
                            <RequireAuth>
                                <Home />
                            </RequireAuth>
                        }
                    />
                    {user && !error && user.org_settings.posts ? (
                        <>
                            <Route
                                path="create"
                                element={
                                    <RequireAuth>
                                        <CreatePost />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="posts"
                                element={
                                    <RequireAuth>
                                        <ViewPosts />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="posts/:slug"
                                element={
                                    <RequireAuth>
                                        <ViewPost />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="posts/:slug/edit"
                                element={
                                    <RequireAuth>
                                        <EditPost />
                                    </RequireAuth>
                                }
                            />
                        </>
                    ) : (
                        <></>
                    )}
                    {user && !error && user.org_settings.products ? (
                        <>
                            <Route
                                path="createproduct"
                                element={
                                    <RequireAuth>
                                        <CreateProduct />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="viewproduct"
                                element={
                                    <RequireAuth>
                                        <ViewProducts />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="product/:slug"
                                element={
                                    <RequireAuth>
                                        <ViewProduct />
                                    </RequireAuth>
                                }
                            />
                        </>
                    ) : (
                        <></>
                    )}
                    {user && !error && user.org_settings.pages ? (
                        <>
                            <Route
                                path="sections"
                                element={
                                    <RequireAuth>
                                        <EditPages />
                                    </RequireAuth>
                                }
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </Route>
            </Routes>
        </AuthProvider>
    );
}

export default App;
