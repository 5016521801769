import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "../../slices/authSlice";
import { TextField, Button, Typography, Box } from "@mui/material";
import styled from "styled-components";
import colors from "../../config/colours.json";

const Form = styled.form`
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: auto;
    padding: 20px;
    background-color: ${colors.Primary};
    border-radius: 5px;
    gap: 25px;
    top: calc(50vh - 32px - 22px - 153px);
    left: calc(50vw - 144px);
    position: absolute;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`;

const Title = styled(Typography)`
    margin-bottom: 20px;
    color: ${colors.Text};
    text-align: center;
`;

const StyledTextField = styled(TextField)`
    margin-bottom: 10px;
`;

const SubmitButton = styled(Button)`
    background-color: ${colors.Accent};
    color: ${colors.Primary};

    &:hover {
        background-color: ${colors.Secondary};
    }
`;

const ErrorMessage = styled(Typography)`
    color: ${colors.TextSecondary};
    text-align: center;
`;

function SignUp() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [adminKey, setAdminKey] = useState("");
    const [orgName, setOrgName] = useState("");
    const dispatch = useDispatch();
    const error = useSelector((state) => state.auth.error);

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(signUp({ username, password, adminKey, orgName }));
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Title variant="h4">Sign Up</Title>
            <StyledTextField
                label="Username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
            />
            <StyledTextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            <StyledTextField
                label="Organization Name"
                type="text"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
                required
            />
            <StyledTextField
                label="Admin API Key"
                type="password"
                value={adminKey}
                onChange={(e) => setAdminKey(e.target.value)}
                required
            />
            <SubmitButton type="submit" variant="contained">
                Sign Up
            </SubmitButton>
            {error && <ErrorMessage variant="body1">{JSON.stringify(error)}</ErrorMessage>}
        </Form>
    );
}

export default SignUp;
