import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    TextField,
    TextareaAutosize,
    FormControlLabel,
    Radio,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { calculateFormDataSize } from "../../config/utils";
import UploadWithPreview from "../Post/Common/UploadWithPreview";
import {
    StyledLabel,
    Form,
    Title,
    SubmitButton,
    ErrorMessage,
    StyledRadioGroup,
    StyledTextArea,
} from "../Post/Common/Ui";
import { createProduct } from "../../slices/productSlice";
import { SlugField } from "../Post/Common/FormComponents";
function NewProduct() {
    const { user } = useSelector((state) => state.auth);
    const [mainImage, setMainImage] = useState(null); // default to null
    const [mainDescription, setMainDescription] = useState("");
    const [image1, setImage1] = useState("");
    const [image2, setImage2] = useState("");
    const [description2, setDescription2] = useState("");
    const [additionalMedia, setAdditionalMedia] = useState("");
    const [title, setTitle] = useState("");
    const [slug, setSlug] = useState("");
    const [content, setContent] = useState("");
    const [status, setStatus] = useState("draft");
    const [productPrice,setPrice] = useState("")
    const [publishedAt, setPublishedAt] = useState(new Date());

    const dispatch = useDispatch();
    const error = null;

    const onDropMainImage = useCallback((acceptedFiles) => {
        setMainImage(acceptedFiles[0]);
    }, []);

    const {
        getRootProps: getRootPropsMainImage,
        getInputProps: getInputPropsMainImage,
    } = useDropzone({
        onDrop: onDropMainImage,
        multiple: false,
        disabled: !!mainImage,
    });

    const onDropImage1 = useCallback((acceptedFiles) => {
        setImage1(acceptedFiles[0]);
    }, []);

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } =
        useDropzone({
            onDrop: onDropImage1,
            multiple: false,
            disabled: !!image1,
        });

    const onDropImage2 = useCallback((acceptedFiles) => {
        setImage2(acceptedFiles[0]);
    }, []);

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
        useDropzone({
            onDrop: onDropImage2,
            multiple: false,
            disabled: !!image2,
        });

    const onDropAdditionalMedia = useCallback((acceptedFiles) => {
        setAdditionalMedia(acceptedFiles[0]);
    }, []);

    const {
        getRootProps: getRootPropsAdditionalMedia,
        getInputProps: getInputPropsAdditionalMedia,
    } = useDropzone({
        onDrop: onDropAdditionalMedia,
        multiple: false,
        disabled: !!additionalMedia,
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        setContent(
            JSON.stringify({
                mainDescription,
                description2,
            })
        );
    };

    useEffect(() => {
        if (content === "") return;
        const formData = new FormData();
        formData.append("name", title);
        formData.append("slug", slug);
        formData.append("description", content);
        formData.append("author_id", user.id);
        formData.append("inStock", status);
        formData.append("published_at", publishedAt);
        formData.append("price", productPrice);
        // Create attachments array - order is important
        const attachments = [mainImage, image1, image2, additionalMedia];
        attachments.forEach((attachment) => {
            formData.append(`attachments[]`, attachment);
        });
        try {
            dispatch(createProduct(formData));
            console.log("Product created successfully.");
        } catch (error) {
            console.error("Failed to create product: ", error);
        }
        console.log("Product data:", formData, calculateFormDataSize(formData));
    }, [content]);

    return (
        <div
            style={{
                // two column grid
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                // center the grid
                placeContent: "center",
            }}
        >
            <Form onSubmit={handleSubmit}>
                <Title variant="h4">Create New Product</Title>
                <TextField
                    label="Product Name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />
                <TextField
                    label="Product Price"
                    value={productPrice}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                />
                <SlugField
                    slug={slug}
                    setSlug={setSlug}
                />
                <UploadWithPreview
                    label="Main Image"
                    value={mainImage}
                    setValue={setMainImage}
                />
                <StyledTextArea
                    minRows={3}
                    style={{ width: "100%" }}
                    aria-label="Main Description"
                    placeholder="Main Description"
                    value={mainDescription}
                    onChange={(e) => setMainDescription(e.target.value)}
                    required
                />
                <UploadWithPreview
                    label="Image 1"
                    value={image1}
                    setValue={setImage1}
                />
                <UploadWithPreview
                    label="Image 2"
                    value={image2}
                    setValue={setImage2}
                />
                <StyledTextArea
                    minRows={3}
                    style={{ width: "100%" }}
                    aria-label="Description 2"
                    placeholder="Description 2"
                    value={description2}
                    onChange={(e) => setDescription2(e.target.value)}
                />
                <UploadWithPreview
                    label="Additional Media"
                    value={additionalMedia}
                    setValue={setAdditionalMedia}
                />
                <StyledLabel variant="body1">Status</StyledLabel>
                <StyledRadioGroup
                    row
                    aria-label="status"
                    name="row-radio-buttons-group"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                >
                    <FormControlLabel
                        value="inStock"
                        control={<Radio />}
                        label="In Stock"
                    />
                    <FormControlLabel
                        value="OutOfStock"
                        control={<Radio />}
                        label="Out Of Stock"
                    />
                </StyledRadioGroup>

                <SubmitButton type="submit" variant="contained">
                    Create Product
                </SubmitButton>
                {error && (
                    <ErrorMessage variant="body1">
                        {JSON.stringify(error)}
                    </ErrorMessage>
                )}
            </Form>
        </div>
    );
}

export default NewProduct;
