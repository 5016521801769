import React from "react";
import { Outlet, useNavigate, Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { AppBar, Toolbar, Link as MuiLink } from "@mui/material";
import styled from "styled-components";
import colors from "../../config/colours.json";
import Logo from "../../assets/logoColor.svg";
import AnnouncementBar from "./AnnouncementBar";

const StyledAppBar = styled(AppBar)`
    background-color: ${colors.Primary};
`;

const StyledToolbar = styled(Toolbar)`
    display: flex;
    justify-content: space-between;
    background-color: ${colors.Primary};
`;

const StyledLink = styled(Link)`
    color: ${colors.Text};
    text-decoration: none;
    margin: 0 10px;

    &:hover {
        color: #333;
    }
`;

const Navbar = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    return (
        <div>
            <AnnouncementBar />

            <StyledAppBar position="static">
                <StyledToolbar>
                    <img
                        src={Logo}
                        alt="logo"
                        height="50px"
                        onClick={() => {
                            navigate("/");
                        }}
                    />
                    <div>
                        {auth.user ? (
                            <>
                                <StyledLink to="/">Home</StyledLink>
                                {auth.user.org_settings.pages ? (
                                    <>
                                        <StyledLink to="/sections">
                                            Pages
                                        </StyledLink>
                                    </>
                                ) : null}
                                {auth.user.org_settings.posts ? (
                                    <>
                                        <StyledLink to="/create">
                                            Create Post
                                        </StyledLink>
                                        <StyledLink to="/posts">
                                            View Posts
                                        </StyledLink>
                                    </>
                                ) : null}
                                {auth.user.org_settings.products ? (
                                    <>
                                        <StyledLink to="/createproduct">
                                            Create Product
                                        </StyledLink>
                                        <StyledLink to="/viewproduct">
                                            View Products
                                        </StyledLink>
                                    </>
                                ) : null}
                                <StyledLink
                                    onClick={() => {
                                        auth.signout(() => navigate("/login"));
                                    }}
                                >
                                    Sign Out
                                </StyledLink>
                            </>
                        ) : (
                            <>
                                <StyledLink to="/signup">Sign Up</StyledLink>
                                <StyledLink to="/login">Login</StyledLink>
                            </>
                        )}
                    </div>
                </StyledToolbar>
            </StyledAppBar>

            <Outlet />
        </div>
    );
};

export default Navbar;
