import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../slices/productSlice";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import styled from "styled-components";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const PostCard = styled(Card)`
    margin-bottom: 20px;
    cursor: pointer;
`;

const StyledImg = styled.img`
    max-width: 100px;
    height: auto;
    margin-right: 20px;
`;

const GridItemPadding = styled(Grid)`
    padding-left: 20px;
`;

function ViewProducts() {
    const { products, error } = useSelector((state) => state.products);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(fetchProducts());
    }, []);
    console.log("products Data",products)
    return (
        <div>
            <Typography variant="h4">Products</Typography>
            {products == [] ? (
                <Typography>Loading...</Typography>
            ) : error ? (
                <Typography color="error">Error: {error}</Typography>
            ) : (
                products.map((product) => (
                    <PostCard
                        key={product.id}
                        onClick={() => {
                            navigate(`/product/${product.slug}`);
                        }}
                    >
                        <CardContent>
                            <Grid container>
                                <Grid item xs={1}>
                                    <StyledImg
                                        src={product.featured_image}
                                        alt={product.name}
                                    />
                                </Grid>
                                <GridItemPadding item xs={8}>
                                    <Typography variant="h5">
                                        {product.title}
                                    </Typography>
                                    <Typography variant="body2">
                                        {format(
                                            new Date(product.published_at),
                                            "dd/MM/yyyy"
                                        )}
                                    </Typography>
                                </GridItemPadding>
                            </Grid>
                        </CardContent>
                    </PostCard>
                ))
            )}
        </div>
    );
}

export default ViewProducts;
