import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import postsReducer from "./slices/postsSlice";
import productReducer from "./slices/productSlice";
import pageReducer from "./slices/pageSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        posts: postsReducer,
        products: productReducer,
        pages: pageReducer,
    },
});
