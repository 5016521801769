function calculateFormDataSize(formData) {
    // Create a new Blob object from the FormData
    const blob = new Blob([...formData.values()]);

    // Get the size of the Blob in bytes
    const sizeInBytes = blob.size;

    // Convert the size to human-readable format (e.g., KB, MB)
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;

    return { bytes: sizeInBytes, kilobytes: sizeInKB, megabytes: sizeInMB };
}
// TODO: Add support for other types of input, make more robust
function checkType(input) {
    if (typeof input === "string") {
        // This could be a URL
        return "URL";
    }

    if (typeof input === "object") {
        // Check for 'name' and 'size' properties that are present in File objects
        if ("name" in input && "size" in input) {
            return "File";
        }
    }

    return "Unknown";
}

export { calculateFormDataSize, checkType };
