import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { fetchPost } from "../../slices/postsSlice";
import colors from "../../config/colours.json";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Title, ViewImage } from "./Common/Ui";

function ViewPost() {
    const { slug } = useParams();
    const { currentPost: post, error } = useSelector((state) => state.posts);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchPost(slug));
    }, [slug]);

    useEffect(() => {
        console.log(post);
    }, [post]);

    return (
        <div>
            {post === null ? (
                <Typography>Loading...</Typography>
            ) : error ? (
                <Typography color="error">Error: {error}</Typography>
            ) : (
                <>
                <Paper
                    elevation={3}
                    style={{
                        padding: "20px",
                        marginTop: "20px",
                        width: "90%",
                        backgroundColor: colors.Primary,
                    }}
                >
                    {console.log(post, "post")}
                    <Title variant="h4">{post.title}</Title>
                    <Typography variant="subtitle1">
                        Author: {post.author_id}
                    </Typography>
                    <Typography variant="subtitle2">
                        Posted: {console.log(post.published_at, "published_at")}
                        {format(new Date(post.published_at), "dd/MM/yyyy")}
                    </Typography>
                    <ViewImage src={post.content["0"]} alt={post.title} />
                    <Typography variant="body1" style={{ margin: "20px 0", whiteSpace: "pre-line" }}>
                        {post.content.mainDescription}
                    </Typography>

                    <Grid container spacing={2} style={{ margin: "20px 0" }}>
                        <Grid item xs={6}>
                            {post.content["1"] && (
                                <ViewImage src={post.content["1"]} alt="Image 1" />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {post.content["2"] && (
                            <ViewImage src={post.content["2"]} alt="Image 2" />
                            )}
                        </Grid>
                    </Grid>

                    <Typography variant="body1" style={{ margin: "20px 0" }}>
                        {post.content.description2}
                    </Typography>
                    {post.content["3"] && (
                    <ViewImage
                        src={post.content["3"]}
                        alt="Additional Media"
                        style={{ width: "100%", margin: "20px 0" }}
                    />
                    )}
                </Paper>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                        console.log("Edit");
                        navigate(`/posts/${post.slug}/edit`);
                    }}
                >
                    Edit
                </Button>
                </>
            )}
        </div>
    );
}

export default ViewPost;
