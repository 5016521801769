import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../config/api";

// Fetch all posts
export const fetchPosts = createAsyncThunk("posts/fetchAll", async (_, thunkAPI) => {
    try {
        const response = await api.get("/post/");
        console.log("response.data", response.data)
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});

// Fetch a specific post
export const fetchPost = createAsyncThunk("posts/fetchPost", async (slug, thunkAPI) => {
    try {
        const response = await api.get(`/post/${slug}`);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});

// Create a new post
export const createPost = createAsyncThunk("posts/createPost", async (formData, thunkAPI) => {
    try {
        const response = await api.post("/post/create", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});

// Update an existing post
export const updatePost = createAsyncThunk("posts/updatePost", async ({id, formData:updatedPost}, thunkAPI) => {
    try {

        for (let pair of updatedPost.entries()) {
            console.log(pair[0]+ ', '+ pair[1]);
        }
        const response = await api.put(`/post/edit/${id}`, updatedPost, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});

// Delete a post
export const deletePost = createAsyncThunk("posts/deletePost", async (postId, thunkAPI) => {
    try {
        await api.delete(`/post/${postId}`);
        return postId;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});

const postsSlice = createSlice({
    name: "posts",
    initialState: {
        posts: [],
        error: null,
        currentPost: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPosts.fulfilled, (state, action) => {
                state.posts = action.payload;
                state.error = null;
            })
            .addCase(fetchPosts.rejected, (state, action) => {
                state.error = action.payload.error;
            })
            .addCase(fetchPost.fulfilled, (state, action) => {
                state.currentPost = action.payload;
                state.error = null;
            })
            .addCase(fetchPost.rejected, (state, action) => {
                state.error = action.payload.error;
            })
            .addCase(createPost.fulfilled, (state, action) => {    
                state.error = null;
            })
            .addCase(createPost.rejected, (state, action) => {
                state.error = action.payload.error;
            })
            .addCase(updatePost.fulfilled, (state, action) => {
                state.error = null;
            })
            .addCase(updatePost.rejected, (state, action) => {
                state.error = action.payload.error;
            })
            .addCase(deletePost.fulfilled, (state, action) => {
                state.error = null;
            })
            .addCase(deletePost.rejected, (state, action) => {
                state.error = action.payload.error;
            });
    },
});

export default postsSlice.reducer;
