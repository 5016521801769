import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../config/api";

// Fetch all posts
export const fetchProducts = createAsyncThunk("products/fetchAll", async (_, thunkAPI) => {
    try {
        const response = await api.get("/product/");
        console.log("response.data", response.data)
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});

// Fetch a specific product
export const fetchProduct = createAsyncThunk("posts/fetchProduct", async (slug, thunkAPI) => {
    try {
        const response = await api.get(`/product/${slug}`);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});

// Create a new product
export const createProduct = createAsyncThunk("product/createProduct", async (formData, thunkAPI) => {
    try {
        console.log("Creating Product : ",formData)
        const response = await api.post("/product/create", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});

// Update an existing product
export const updateProduct = createAsyncThunk("product/updateProduct", async ({id, formData:updatedPost}, thunkAPI) => {
    try {

        for (let pair of updatedPost.entries()) {
            console.log(pair[0]+ ', '+ pair[1]);
        }
        const response = await api.put(`/product/edit/${id}`, updatedPost, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});

// Delete a product
export const deleteProduct = createAsyncThunk("product/deleteProduct", async (postId, thunkAPI) => {
    try {
        await api.delete(`/product/${postId}`);
        return postId;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});

const productSlice = createSlice({
    name: "products",
    initialState: {
        products: [],
        error: null,
        currentProduct: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.products = action.payload;
                state.error = null;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.error = action.payload.error;
            })
            .addCase(fetchProduct.fulfilled, (state, action) => {
                state.currentProduct = action.payload;
                state.error = null;
            })
            .addCase(fetchProduct.rejected, (state, action) => {
                state.error = action.payload.error;
            })
            .addCase(createProduct.fulfilled, (state, action) => {    
                state.error = null;
            })
            .addCase(createProduct.rejected, (state, action) => {
                state.error = action.payload.error;
            })
            .addCase(updateProduct.fulfilled, (state, action) => {
                state.error = null;
            })
            .addCase(updateProduct.rejected, (state, action) => {
                state.error = action.payload.error;
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                state.error = null;
            })
            .addCase(deleteProduct.rejected, (state, action) => {
                state.error = action.payload.error;
            });
    },
});

export default productSlice.reducer;
