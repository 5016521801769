import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Typography, Box, ImageList, ImageListItem } from "@mui/material";
import styled from "styled-components";
import { checkType } from "../../../config/utils";

const DropzoneBox = styled(Box)`
    height: 50px;
    width: 100%;
    border: 2px dashed gray;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    &:hover {
        cursor: pointer;
    }
`;

function UploadWithPreview({ value, setValue, label }) {
    const onDrop = useCallback((acceptedFiles) => {
        setValue(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        disabled: !!value,
    });

    return (
        <div>
            <Typography variant="body1">{`Upload ${label}:`}</Typography>
            <DropzoneBox {...getRootProps()}>
                <input {...getInputProps()} disabled={!!value} />
                {value ? (
                    <p>{value.name}</p>
                ) : (
                    <p>Drag and drop here, or click to select file</p>
                )}
            </DropzoneBox>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                {value && (
                    <>
                        <ImageList cols={1} rowHeight={120}>
                            <ImageListItem>
                                <img
                                    src={
                                        checkType(value) === "URL"
                                            ? value
                                            : URL.createObjectURL(value)
                                    }
                                    alt=""
                                />
                            </ImageListItem>
                        </ImageList>

                        <div
                            style={{
                                backgroundColor: "red",
                                fontSize: "20px",
                                fontWeight: "bold",
                                cursor: "pointer",
                                color: "white",
                                width: "20px",
                                height: "20px",
                                minWidth: "20px",
                                minHeight: "20px",
                                borderRadius: "50%",
                                textAlign: "center",
                                lineHeight: "20px",
                            }}
                            onClick={() => setValue(null)}
                        >
                            x
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default UploadWithPreview;
