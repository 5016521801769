import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, checkAuthStatus } from "../../slices/authSlice";
import { TextField, Button, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import colors from "../../config/colours.json";

const Form = styled.form`
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: auto;
    padding: 20px;
    background-color: ${colors.Primary};
    border-radius: 5px;
    gap: 25px;
    top: calc(50vh - 32px - 22px - 153px);
    left: calc(50vw - 144px);
    position: absolute;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`;

const Title = styled(Typography)`
    margin-bottom: 20px;
    color: ${colors.Text};
    text-align: center;
`;

const StyledTextField = styled(TextField)`
    margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
    background-color: ${colors.Accent};
    color: ${colors.Primary};

    &:hover {
        background-color: ${colors.Secondary};
    }
`;

const ErrorMessage = styled(Typography)`
    color: ${colors.TextSecondary};
    text-align: center;
`;

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const error = useSelector((state) => state.auth.error);
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(login({ username, password })).then((action) => {
            handleLogin(action);
        });
    };

    useEffect(() => {
        // Check user's authentication status on initial load
        dispatch(checkAuthStatus()).then((action) => {
            handleLogin(action);
        });
    }, []);
    
    const handleLogin = (action) => {
        if (action?.payload && action?.payload?.username) {
            console.log("Logged in");
            return navigate("/");
        } else {
            console.log(action);
        }
    }
    return (
        <Form onSubmit={handleSubmit}>
            <Title variant="h4">Log In</Title>
            <StyledTextField
                label="Username"
                type="text"
                value={username}
                autoComplete="username"
                onChange={(e) => setUsername(e.target.value)}
                required
            />
            <StyledTextField
                label="Password"
                type="password"
                value={password}
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            <StyledButton type="submit" variant="contained">
                Log In
            </StyledButton>
            {error && <ErrorMessage variant="body1">{error}</ErrorMessage>}
        </Form>
    );
}

export default Login;
