import styled from "styled-components";
import colors from "../../../config/colours.json";
import {
    Button,
    Typography,
    RadioGroup,
    TextareaAutosize,
} from "@mui/material";
import { ReactSVG } from 'react-svg';

const Img = styled.img`
    max-width: 100%;
    height: auto;
`;

const Svg = styled(ReactSVG)`
    max-width: 100%;
    height: auto;
`;

const ViewImage = ({ src, ...props }) => {
    console.log("src", src)

    if (src && src.endsWith('.svg')) {
        return <Svg src={src} {...props} />;
    } else {
        return <Img src={src} {...props} />;
    }
};

const StyledTextArea = styled(TextareaAutosize)`
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    font-family: inherit;
    line-height: 1.43;
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid rgba(0, 0, 0, 0.23);
    borderradius: 4px;
    transition: all 0.2s;
    border-radius: 5px;

    &:focus {
        outline: none;
        border-color: #999;
    }
    &:hover {
        border-color: #999;
    }
`;
const StyledLabel = styled(Typography)`
    margin-bottom: 10px;
`;
const Form = styled.form`
    display: flex;
    flex-direction: column;
    max-width: 900px;
    margin-top: 20px;
    background-color: ${colors.Primary};
    padding: 20px;
    gap: 25px;
    width: 100%;
`;

const Title = styled(Typography)`
    margin-bottom: 20px;
    color: ${colors.Text};
`;

const SubmitButton = styled(Button)`
    background-color: ${colors.Accent};
    color: ${colors.Primary};

    &:hover {
        background-color: ${colors.Secondary};
    }
`;

const ErrorMessage = styled(Typography)`
    color: ${colors.TextSecondary};
    text-align: center;
`;

const StyledRadioGroup = styled(RadioGroup)`
    display: flex;
    flex-direction: column !important;
    justify-content: space-around;
`;

export {
    StyledLabel,
    Form,
    Title,
    SubmitButton,
    ErrorMessage,
    StyledRadioGroup,
    StyledTextArea,
    ViewImage,
};
