import { TextField } from "@mui/material";
import { useState } from "react";

export const SlugField = ({ slug, setSlug }) => {
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("Changing slug might affect SEO so please be careful.");

    const handleSlugChange = (e) => {
        const newSlug = e.target.value;
        if (/^[a-zA-Z0-9-]*$/.test(newSlug)) {
            setSlug(newSlug);
            setError(false);
            setHelperText("Changing slug might affect SEO so please be careful.");
        } else {
            setError(true);
            setHelperText('Invalid slug! Only letters, numbers and hyphens (-) are allowed.');
        }
    }

    return (
        <TextField
            label="Slug"
            required
            error={error}
            helperText={helperText}
            value={slug}
            onChange={handleSlugChange}
        />
    );
}
